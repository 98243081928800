import { createAction, props } from '@ngrx/store';
import { Company, UserInfo } from './company.model';

export enum CompanyActionType {
  GET_ALL_COMPANIES = '[Company] Get All Companies',
  GET_ALL_COMPANIES_SUCCESS = '[Company] Get All Companies :: Success',
  GET_COMPANY = '[Company] Get Company',
  GET_COMPANY_SUCCESS = '[Company] Get Company :: Success',
  GET_REPORT = '[Company] Get Company Report',
  GET_REPORT_SUCCESS = '[Company] Get Company Report :: Success',
  CLEAR_COMPANY = '[Company] Clear Company',
  CREATE_COMPANY = '[Company] Create Company',
  CREATE_COMPANY_SUCCESS = '[Company] Create Company :: Success',
  UPDATE_COMPANY = '[Company] Update Company',
  UPDATE_COMPANY_SUCCESS = '[Company] Update Company :: Success',
  RESET_COMPANY_UPDATED = '[Company] Reset Company Updated',
  DELETE_COMPANY = '[Company] Delete Company',
  DELETE_COMPANY_SUCCESS = '[Company] Delete Company :: Success',
  RESET_COMPANY_DELETED = '[Company] Reset Company Deleted',
  SET_USER_INFO = '[Company] Set User Info',
  RESET_USER_INFO = '[Company] Reset User Info'
}

export const getAllCompanies = createAction(CompanyActionType.GET_ALL_COMPANIES);
export const getAllCompaniesSuccess = createAction(
  CompanyActionType.GET_ALL_COMPANIES_SUCCESS,
  props<{ companies: Company[] }>()
);
export const getCompany = createAction(CompanyActionType.GET_COMPANY, props<{ id: string }>());
export const getCompanySuccess = createAction(
  CompanyActionType.GET_COMPANY_SUCCESS,
  props<{ company: Company }>()
);
export const getReport = createAction(CompanyActionType.GET_REPORT, props<{ id: number }>());
export const getReportSuccess = createAction(CompanyActionType.GET_REPORT_SUCCESS, props<{ company: Company }>());
export const clearCompany = createAction(CompanyActionType.CLEAR_COMPANY);
export const createCompany = createAction(
  CompanyActionType.CREATE_COMPANY,
  props<{ company: Company }>()
);
export const createCompanySuccess = createAction(
  CompanyActionType.CREATE_COMPANY_SUCCESS,
  props<{ company: Company }>()
);
export const updateCompany = createAction(
  CompanyActionType.UPDATE_COMPANY,
  props<{ company: Company }>()
);
export const updateCompanySuccess = createAction(
  CompanyActionType.UPDATE_COMPANY_SUCCESS,
  props<{ company: Company }>()
);
export const resetCompanyUpdated = createAction(CompanyActionType.RESET_COMPANY_UPDATED);
export const deleteCompany = createAction(
  CompanyActionType.DELETE_COMPANY,
  props<{ id: number }>()
);
export const deleteCompanySuccess = createAction(CompanyActionType.DELETE_COMPANY_SUCCESS);
export const resetCompanyDeleted = createAction(CompanyActionType.RESET_COMPANY_DELETED);
export const setUserInfo = createAction(CompanyActionType.SET_USER_INFO, props<{ userInfo: UserInfo }>());
export const resetUserInfo = createAction(CompanyActionType.RESET_USER_INFO);
